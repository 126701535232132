<template>
  <Loader :value="promises.fetch" class="post-subpage">
    <component v-if="component" :is="component" :value="post" :key="post.id" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Types from './Types'

export default {
  props: {
    postSlug: {
      type: String,
      default: 'post',
    },
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Post', ['promises', 'item']),

    post() {
      if (!this.item.posts) return {}
      let post = this.item.posts.find(p => p.slug === this.slug)
      return post
    },

    component() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || []

      let key = (sources[0] || '').replace('drammen-syd', '')

      switch (key) {
        case 'article':
          return Types.Article

        default:
          return Types.Article
      }
    },
  },
  methods: {
    ...mapActions('Post', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    ...Types,
  },
}
</script>

<style lang="scss">
.post__content p:first-of-type {
  font-size: initial;
}
</style>
